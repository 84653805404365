<div class="join-our-network-wrapper">
  <div class="row">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="row">
        <div class="joining-header-image">
          <img class="image-1" src="../../../assets/images/shaped/long-horizontal-members-1.png" alt="">
        </div>
        <div class="joining-contact-wrapper">
          <div class="row">
            <div class="d-none d-md-inline-block col-md-4">
              <img class="contact-image" src="../../../assets/images/vector/laptop-1.svg" alt="">
            </div>
            <div class="col-sm-12 col-md-8">
              <address class=" harmony-secondary-text p-4">
                <a class="harmony-tertiary-link fw-bold" [routerLink]="['/member/1']">Harmony Relocation B.V.</a>
                <br>
                <small class="fw-bold">International Head Office</small>
                <br>
                <small>Nijverheidslaan 3 A21</small>
                <br>
                <small>1382LE Weesp (Amsterdam)</small>
                <br>
                <small>The Netherlands</small>
                <br>
                <small>T: <a href="tel:+31-294-415-012" class="harmony-secondary-link">+31 294 415 012</a></small>
                <br/>
                <button class="btn btn-sm btn-primary harmony-tertiary-button w-100 mt-3" (click)="router.navigate(['contact'])">Contact</button>
              </address>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-4 p-md-3">
        <div class="col-12">
          <h2 class="harmony-primary-text fw-bold">
            Is your company interested in becoming a member of Harmony Relocation Network?
          </h2>
          <p class="harmony-accent-grey-text">
            Harmony Relocation Network members provide household goods moving and/or destination services. Becoming
            part of Harmony means investing in a stable international network organisation that enables you to
            achieve a sustainable competitive advantage in your local market. Choosing Harmony does not mean losing
            your independence - on the contrary in fact. The concept of ownership means you can benefit from the
            support services when needed, thereby strengthening your own company, and, in turn, your independence.
          </p>
          <p class="harmony-accent-grey-text">
            Becoming a member of Harmony Relocation Network means working together in partnership with the Harmony
            Network and with our member companies around the world. We’d love to chat about how together, we can build
            a successful future for your company as a part of the Harmony Relocation Network. Please
            <a (click)="router.navigate(['contact'])" class="harmony-tertiary-link">get in touch</a>.
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 mt-3 mt-md-0 col-md-6 col-xl-4">
          <div class="join-card unique-network-card">
            <div class="row">
              <div class="col-md-12 text-center">
                <h3 class="fw-bold">
                  A unique network
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>
                  Harmony Relocation head office operates as a shared service centre for its members. From the head office we
                  provide services that add value to member companies including ISO Group certification(s), Compliance,
                  Technology, Marketing & Sales support, Netting and other Financial services, Reporting, Quality measurement, group procurement
                  of services etc.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 mt-3 mt-md-0 col-md-6 col-xl-4">
          <div class="join-card semi-exclusive-card">
            <div class="row">
              <div class="col-md-12 text-center">
                <h3 class="fw-bold">
                  Semi-exclusive
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>
                  Harmony limits the number of members in any given market. Given the size of a market/country, we aim to keep
                  a healthy balance between the number of members in that market and our market share.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 mt-3 mt-xl-0 col-md-12 col-xl-4">
          <div class="join-card governance-card">
            <div class="row">
              <div class="col-md-12 text-center">
                <h3 class="fw-bold">
                  Governance structure
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>
                  Solid checks & balances are key for the long-term stability of any organisation. Our organisation was created
                  to remain a member-owned network and it cannot be acquired by another organisation. Harmony Relocation is
                  ultimately governed by a Dutch foundation. The people on the Board of the foundation are elected by the
                  member companies. The Board supervises the management of the organisation. Head office strategy and
                  operations are managed by a small team of professionals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
