<footer class="harmony-footer-web">
  <div class="row mb-5">
    <div class="col-2 offset-1 footer-section">
      <ul class="list-unstyled">
        <li><span class="footer-section-title harmony-primary-text">General</span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/about-us/history']">About Us</a></span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/global-network']">Global Network</a></span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/insights']">News & Insights</a></span></li>
      </ul>
    </div>
    <div class="col-2 footer-section">
      <ul class="list-unstyled">
        <li><span class="footer-section-title harmony-primary-text">In Detail</span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/about-us/clients']">Our Clients</a></span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/about-us/services']">Our Services</a></span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/about-us/people']">Our People</a></span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/about-us/commitment']">Our Commitment</a></span></li>
      </ul>
    </div>
    <div class="col-2 footer-section">
      <ul class="list-unstyled">
        <li><span class="footer-section-title harmony-primary-text">Member</span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/joining-our-network']">Joining our Network</a></span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" href="#" (click)="goToPortal()">Portal login</a></span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" href="https://intranet.harmonyrelo.com/">Intranet</a></span></li>
      </ul>
    </div>
    <div class="col-2 footer-section">
      <ul class="list-unstyled">
        <li><span class="footer-section-title harmony-primary-text">Contact</span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/contact']">Contact Details</a></span></li>
        <li><span class="footer-section-item"><a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/direct-quote']">Direct Quote</a></span></li>
      </ul>
    </div>
    <div class="col-2 footer-section">
      <h3 class="harmony-primary-text fw-bold">Harmony. <br> Feel at home the world over.</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-5 offset-1 text-start">
      <p class="mb-0 fw-bold">&copy; {{ getYear() }}, Harmony Relocation B.V.</p>
      <a class="harmony-accent-grey-link" [routerLink]="['/policies/cookies']">Cookie Policy</a> |
      <a class="harmony-accent-grey-link" [routerLink]="['/policies/privacy']">Privacy Policy</a> |
      <a class="harmony-accent-grey-link" [routerLink]="['/policies/disclaimer']">Disclaimer</a> |
      <a class="harmony-accent-grey-link" [href]="codeOfConductUrl" target="_blank">Code of Conduct</a>
    </div>
    <div class="col-5 text-end">
      <div class="harmony-accent-grey-text pointer" (click)="scrollToTop()">
        <fa-icon [icon]="faScrollToTop" size="2x"></fa-icon>
      </div>
    </div>
  </div>
</footer>
<footer class="harmony-footer-mobile">
  <div class="row">
    <div class="col-12">
      <div class="row pb-4 footer-expand-link">
        <div class="col-12 text-start">
          <h3 class="harmony-primary-text fw-bold">Contact</h3>
          <h6 class="harmony-accent-grey-text lh-1 fw-bold">Harmony Relocation B.V.</h6>
          <h6 class="harmony-accent-grey-text lh-1">International Head Office</h6>
          <h6 class="harmony-accent-grey-text lh-1">Nijverheidslaan 3 A21</h6>
          <h6 class="harmony-accent-grey-text lh-1">1382 LE Weesp</h6>
          <h6 class="harmony-accent-grey-text lh-1">The Netherlands</h6>
          <a href="tel:+31-294-415-012" class="harmony-primary-link fw-bold">
            +31 294 415 012
          </a>
          <br/>
          <a href="mailto:info@harmonyrelo.com" class="harmony-primary-link fw-bold">
            info&#64;harmonyrelo.com
          </a>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12 text-start">
          <a class="footer-expand-link"
             data-bs-toggle="collapse"
             href="#collapseGeneral"
             role="button"
             aria-expanded="false"
             aria-controls="collapseGeneral">
          <span class="footer-section-title harmony-primary-text pb-3">
            <fa-icon [icon]="faArrowRight" size="sm"></fa-icon> General
          </span>
          </a>
          <div class="collapse" id="collapseGeneral">
            <div class="card card-body footer-card-body">
              <div class="footer-section">
                <ul class="list-unstyled mb-0">
                  <li>
                    <span class="footer-section-item">
                      <a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/about-us/history']">
                        About us
                      </a>
                    </span>
                  </li>
                  <li>
                    <span class="footer-section-item">
                      <a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/global-network']">
                        Global Network
                      </a>
                    </span>
                  </li>
                  <li>
                    <span class="footer-section-item">
                      <a class="text-decoration-none harmony-accent-grey-link" [routerLink]="['/insights']">
                        Insights
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12 text-start">
          <a class="footer-expand-link" data-bs-toggle="collapse" href="#collapseMember" role="button" aria-expanded="false" aria-controls="collapseMember">
          <span class="footer-section-title harmony-primary-text pb-3">
            <fa-icon [icon]="faArrowRight" size="sm"></fa-icon> In Detail
          </span>
          </a>
          <div class="collapse" id="collapseMember">
            <div class="card card-body footer-card-body">
              <div class="footer-section">
                <ul class="list-unstyled mb-0">
                  <li>
                    <span class="footer-section-item">
                      <a class="text-decoration-none harmony-accent-grey-text" [routerLink]="['/about-us/clients']">
                        Our Clients
                      </a>
                    </span>
                  </li>
                  <li>
                    <span class="footer-section-item">
                      <a class="text-decoration-none harmony-accent-grey-text" [routerLink]="['/about-us/services']">
                        Our Services
                      </a>
                    </span>
                  </li>
                  <li>
                    <span class="footer-section-item">
                      <a class="text-decoration-none harmony-accent-grey-text" [routerLink]="['/about-us/people']">
                        Our People
                      </a>
                    </span>
                  </li>
                  <li>
                    <span class="footer-section-item">
                      <a class="text-decoration-none harmony-accent-grey-text" [routerLink]="['/about-us/commitment']">
                        Our Commitment
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12 text-start">
          <a class="footer-expand-link" data-bs-toggle="collapse" href="#collapseDetail" role="button" aria-expanded="false" aria-controls="collapseDetail">
          <span class="footer-section-title harmony-primary-text pb-3">
            <fa-icon [icon]="faArrowRight" size="sm"></fa-icon> Member
          </span>
          </a>
          <div class="collapse" id="collapseDetail">
            <div class="card card-body footer-card-body">
              <div class="footer-section">
                <ul class="list-unstyled mb-0">
                  <li>
                    <span class="footer-section-item">
                      <a class="text-decoration-none harmony-accent-grey-text" [routerLink]="['/joining-our-network']">
                        Joining our Network
                      </a>
                    </span>
                  </li>
                  <li>
                    <span class="footer-section-item">
                      <a class="text-decoration-none harmony-accent-grey-text" [routerLink]="['/login']">
                        Member login
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3 pt-2 footer-expand-link">
        <div class="col-12 text-start">
          <h3 class="harmony-primary-text fw-bold">Harmony.</h3>
          <h3 class="harmony-primary-text fw-bold">Feel at home the world over.</h3>
        </div>
      </div>
      <div class="row footer-expand-link">
        <div class="col-8 text-start">
          <p class="mb-0 fw-bold">&copy; {{ getYear() }}, Harmony Relocation B.V.</p>
          <a class="harmony-accent-grey-link" [routerLink]="['/policies/cookies']">Cookie Policy</a> |
          <a class="harmony-accent-grey-link" [routerLink]="['/policies/privacy']">Privacy Policy</a> |
          <a class="harmony-accent-grey-link" [routerLink]="['/policies/disclaimer']">Disclaimer</a> |
          <a class="harmony-accent-grey-link" [href]="codeOfConductUrl" target="_blank">Code of Conduct</a>
        </div>
        <div class="col-3 text-end">
          <div class="harmony-accent-grey-text pointer" (click)="scrollToTop()">
            <fa-icon [icon]="faScrollToTop" size="2x"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
